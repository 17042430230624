import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);

window.addEventListener('popstate', () => {
    window.location.reload();
});

app.use(router).mount('#app')
