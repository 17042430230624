<template>
  <div class="login-container">
    <div class="login-card">
      <h1 class="login-title">Connexion</h1>
      <form @submit.prevent="login" class="form-container">
        <div class="input-group">
          <input
              v-model="credentials.username"
              type="text"
              placeholder="Username"
              required
          />
        </div>
        <div class="input-group">
          <input
              v-model="credentials.password"
              type="password"
              placeholder="Password"
              required
          />
        </div>
        <div class="input-group stay-signed-in">
          <input v-model="staySignedIn" type="checkbox" id="staySignedIn" />
          <label for="staySignedIn">Stay signed in</label>
        </div>
        <div class="input-group">
          <button type="submit" class="login-btn">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true, // Enable sending cookies over CORS
        headers: {
            'Content-Type': 'application/json',
            // Vous pouvez ajouter ici des en-têtes ou des configurations supplémentaires
        },
    });

    export default {
        data() {
            return {
                credentials: {
                    username: '',
                    password: ''
                },
                staySignedIn: false // Ajout de l'état pour la case à cocher
            };
        },
        mounted() {
            // Vérifiez si le cookie "username" existe lorsque le composant est monté
            if (this.getUsernameFromCookie()) {
                this.$router.push({ name: 'RoomSelection' }); // Redirigez si l'utilisateur est déjà connecté
            }
        },
        methods: {
            getUsernameFromCookie() {
                const match = document.cookie.match(new RegExp('(^| )username=([^;]+)'));
                if (match) {
                    return match[2];
                }
                return null;
            },
            async login() {
                try {
                    const response = await apiClient.post('/api/login', this.credentials);
                    const { access_token } = response.data;
                    // Stocker le token
                    localStorage.setItem('access_token', access_token);
                    // Configurer l'intercepteur pour les futures requêtes
                    apiClient.interceptors.request.use(config => {
                        config.headers.Authorization = `Bearer ${access_token}`;
                        return config;
                    });
                    // Définir le cookie avec la durée appropriée
                    if (this.staySignedIn) {
                        document.cookie = `username=${this.credentials.username};path=/;max-age=${30 * 24 * 60 * 60}`;
                    } else {
                        document.cookie = `username=${this.credentials.username};path=/`;
                    }
                    // Redirection
                    this.$router.push({ name: 'RoomSelection' });
                } catch (error) {
                    // Gérer les erreurs de connexion
                    console.error('Erreur de connexion:', error);
                }
            }
        }
    };
</script>

<style scoped>
/* Conteneur principal centré */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
  url('@/assets/background.jpg') no-repeat center center fixed;
  background-size: cover;
}

@media (min-aspect-ratio: 21/9) {
  .login-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url('@/assets/background_2.jpg') no-repeat center center fixed;
  }
}

/* Carte de connexion */
.login-card {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px 50px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  width: 350px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Titre */
.login-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #4caf50;
}

/* Champs de saisie */
.input-group {
  margin-bottom: 20px;
  text-align: left;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease;
  outline: none;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

input:focus {
  border-color: #4caf50;
  background: rgba(255, 255, 255, 0.2);
}

.stay-signed-in {
  display: flex;
  align-items: center; /* Aligne verticalement au centre */
  justify-content: flex-start; /* Aligne horizontalement */
}

.stay-signed-in input[type="checkbox"] {
  margin: 0; /* Retire toute marge par défaut */
  padding: 0;
  width: 16px; /* Ajuste la taille de la case */
  height: 16px;
}

.stay-signed-in label {
  margin: 0; /* Retire toute marge */
  padding-left: 5px; /* Très léger espacement entre la case et le texte */
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8); /* Adouci le texte */
}


/* Bouton */
.login-btn {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
