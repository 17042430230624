<template>
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Welcome to AVGMQ</title>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="styles.css">
  </head>
  <body>
  <div class="container">
    <h1 class="welcome-title">Welcome to <span class="brand-name">AVGMQ</span></h1>
    <div class="buttons">
      <router-link to="/login" class="button">Login</router-link>
      <router-link to="/register" class="button">Register</router-link>
    </div>
  </div>
  </body>
</template>

<script>
    export default {
        name: 'HomeView',
    };
</script>

<style scoped>

/* Styles globaux */
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
  url('@/assets/background.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-aspect-ratio: 21/9) {
  body { 
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url('@/assets/background_2.jpg') no-repeat center center fixed;
  }
}

.buttons a {
  margin: 0 10px; /* Ajoute 10px de marge de chaque côté */
  text-decoration: none; /* Supprime les soulignements */
  color: #4caf50; /* Exemple de couleur */
  font-weight: 600;
  font-size: 1.1rem;
}

.buttons {
  margin-top: 20px; /* Espacement entre le titre et les boutons */
}

.buttons a:hover {
  color: #81c784; /* Effet au survol */
}

/* Conteneur principal */
.container {
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s ease-in-out;
}

/* Titre principal */
.welcome-title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #ffffff;
}

.brand-name {
  color: #4caf50;
}

/* Boutons */
.btn {
  font-size: 1.1rem;
  color: #fff;
  background-color: #4caf50;
  border: none;
  padding: 12px 30px;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.register-btn {
  background-color: #2196F3;
}

.register-btn:hover {
  background-color: #1976D2;
}

/* Animation fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
