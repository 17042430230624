<template>
  <div class="open-rooms-container">
    <div class="room-list-card">
      <h1 class="title">Open Rooms</h1>
      <ul class="room-list">
        <li v-for="room in rooms" :key="room.id" @click="selectRoom(room)" class="room-item">
          <span>{{ room.name }}</span>
          <span v-if="room.requiresPassword" class="password-protected">(Password Protected)</span>
        </li>
      </ul>
    </div>

    <div v-if="selectedRoom" class="password-form-card">
      <h2 class="subtitle">Join Room: {{ selectedRoom.name }}</h2>
      <form @submit.prevent="joinRoom" class="form-container">
        <div v-if="selectedRoom.requiresPassword">
          <input
              v-model="password"
              type="password"
              placeholder="Enter Password"
              required
          />
        </div>
        <button type="submit" class="join-btn">Join Room</button>
      </form>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>


<script>
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    export default {
        data() {
            return {
                rooms: [],
                selectedRoom: null,
                password: '',
                errorMessage: '',
                isLoading: false
            };
        },
        created() {
            this.fetchRooms();
        },
        methods: {
            fetchRooms() {
                apiClient.get('/api/open_rooms')
                    .then(response => {
                        this.rooms = response.data.rooms;
                    })
                    .catch(error => {
                        console.error('Error fetching rooms:', error);
                        this.errorMessage = 'Failed to load rooms. Please try again later.';
                    });
            },
            selectRoom(room) {
                this.selectedRoom = room;
                this.password = '';  // Réinitialiser le mot de passe
                this.errorMessage = '';
            },
            getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
                return '';
            },
            joinRoom() {
                const username = this.getCookie('username');
                if (!username) {
                    this.errorMessage = 'You must be logged in to join a room.';
                    return;
                }

                const joinDetails = {
                    roomId: this.selectedRoom.id,
                    password: this.password,
                    username: username
                };

                this.errorMessage = '';
                this.isLoading = true;
                apiClient.post('/api/join_private_room', joinDetails)
                    .then(response => {
                        console.log('Successfully joined the room:', response.data);
                        localStorage.setItem('room_token', response.data.access_token);
                        this.$router.push({ name: 'PrivateRoom', params: { roomId: joinDetails.roomId } });
                    })
                    .catch(error => {
                        console.error('Error joining room:', error);
                        this.errorMessage = 'Failed to join the room. Please check your details and try again.';
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    };
</script>

<style scoped>
/* Conteneur principal */
.open-rooms-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
  url('@/assets/background.jpg') no-repeat center center fixed;
  background-size: cover;
  gap: 20px;
}

@media (min-aspect-ratio: 21/9) {
  .open-rooms-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url('@/assets/background_2.jpg') no-repeat center center fixed;
  }
}

/* Carte des salles */
.room-list-card, .password-form-card {
  background: rgba(0, 0, 0, 0.6);
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  width: 400px;
  color: #ffffff;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Titres */
.title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #4caf50;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #81c784;
}

/* Liste des salles */
.room-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.room-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  margin: 5px 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.room-item:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.password-protected {
  font-size: 0.9rem;
  color: #ffcc00;
}

/* Formulaire */
.form-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease;
}

.form-container input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-container input:focus {
  border-color: #4caf50;
  background: rgba(255, 255, 255, 0.2);
  outline: none;
}

/* Bouton */
.join-btn {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.join-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

/* Message d'erreur */
.error-message {
  color: #ff6b6b;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Animation fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
