<template>
  <div class="room-container">

    <!-- Top Bar -->
    <div class="top-bar">
      <div class="volume-wrapper">
        <div class="volume-icon" @click="toggleVolumeSlider">
          <i v-if="volume > 0" class="fa-solid fa-volume-high"></i>
          <i v-else class="fa-solid fa-volume-xmark"></i>
        </div>
        <transition name="fade">
          <div class="volume-slider-container" v-if="showVolumeSlider">
            <input
                type="range"
                min="0"
                max="100"
                v-model="volume"
                @input="updateVolume"
            />
          </div>
        </transition>
      </div>
      <div class="song-info">Number of Songs: {{ currentSong }} / {{ roomDetails.numberOfSongs }}</div>
    </div>

    <!-- Room Title -->
    <div class="room-title">
      <h1>Room: <span class="room-name">{{ roomDetails.name }}</span></h1>
      <div class="room-buttons">
        <button v-if="showPlayButton && roomDetails.isCreator" @click="playMusic" class="play-button">Play</button>
        <button v-if="showPlayButton && roomDetails.isCreator" @click="openSettings" class="settings-button">Settings</button>
      </div>
    </div>

    <!-- Left Section: Scores -->
    <div class="left-section">
      <h2>Scores</h2>
      <table class="scoreboard-table">
        <thead>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>Score</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(player, index) in sortedScores"
            :key="player.username"
            :class="[
                     getPlayerClass(player),               // found / unfound / failed
                     { currentUser: player.username === username } // si c'est l'utilisateur local
                     ]"
        >
          <td>{{ index + 1 }}</td>
          <td>
            <span v-if="player.rank === 1" class="goldMedal">🥇</span>
            <span v-else-if="player.rank === 2" class="silverMedal">🥈</span>
            <span v-else-if="player.rank === 3" class="bronzeMedal">🥉</span>
            {{ player.username }}

            <span
                v-if="userScores[player.username] && userScores[player.username].isFastest"
                class="crown"
            >
             👑
            </span>
          </td>
          <td>{{ player.score }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Right Section: Response -->
    <div class="right-section">
      <div class="response-block">
        <div class="time-container">
          <div
              class="time-bar"
              :style="{
                width: timeBarWidth + '%',
                backgroundColor: timeBarColor
              }"
          >
            {{ Math.floor(remainingTime) }}s
          </div>
        </div>
        <input
            type="text"
            class="inputResponse"
            :disabled="!canUserRespond()"
            ref="inputElement"
            v-model="search"
            @input="filterGames"
            @keydown.down.prevent="onArrowDown"
            @keydown.up.prevent="onArrowUp"
            @keyup.enter="validateInput"
            @keydown.tab.prevent="onTab"
            placeholder="Enter a game..."
        />
        <ul class="sugest" ref="suggestionList" v-if="filteredGames.length">
          <li
              v-for="(gameName, index) in filteredGames"
              :key="index"
              :class="{ selected: index === selectedIndex }"
              @click="selectGame(gameName)"
              @mouseenter="() => (selectedIndex = index)"
              ref="suggestionItem"
          >
            {{ gameName }}
          </li>
        </ul>

        <p>Remaining Tries:
          <span v-for="n in maxTries - responses" :key="n" class="heart">❤️</span>
        </p>

      </div>
    </div>

    <!-- Response Details -->
    <div v-if="remainingTime <= 0" class="response-details">
      <h2>Game/Anime Details:</h2>
      <h3>Title: {{ currentGame }}</h3>
      <img v-if="boxArtUrl" :src="boxArtUrl" alt="Game Cover Art" class="box-art" />
      <h3>Song: {{ music_title }}</h3>
      <h3>Composer: {{ composer }}</h3>
      <div>
        <h3 v-if="fastestTime">Fastest Response This Session: {{ fastestTime }}</h3>
        <h3 v-if="fastestResponseForGame">Fastest Response All Sessions: {{ fastestResponseForGame }}</h3>
      </div>
    </div>

    <!-- Settings Modal -->
    <div v-if="showSettingsModal" class="settings-modal">
      <div class="settings-content">
        <h2>Room Settings</h2>
        <label for="numberOfSongs">Number of Songs:</label>
        <input type="number" id="numberOfSongs" v-model="roomDetails.numberOfSongs" min="1" />

        <div>
          <input type="checkbox" id="includeGames" v-model="roomDetails.includeGames" />
          <label for="includeGames">Include Games</label>
        </div>

        <div>
          <input type="checkbox" id="includeAnimes" v-model="roomDetails.includeAnimes" />
          <label for="includeAnimes">Include Animes</label>
        </div>

        <div>
          <input type="checkbox" id="includeIGDBList" v-model="roomDetails.includeIGDBList" />
          <label for="includeIGDBList">Use Game List</label>
        </div>

        <div>
          <input type="checkbox" id="includeAnimeList" v-model="roomDetails.includeAnimeList" />
          <label for="includeAnimeList">Include Anime List</label>
        </div>

        <button @click="saveSettings">Save Settings</button>
      </div>
    </div>

    <!-- Hidden Audio Player -->
    <audio ref="audioPlayer" class="hidden">
      <source :src="audioSource" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
import axios from 'axios';
import io from 'socket.io-client';

const apiClient = axios.create({
  baseURL: 'https://avgmq.net',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  data() {
    return {
      roomId: this.$route.params.roomId,
      roomDetails: {},
      clockOffset: 0,  // Décalage client-serveur
      isLoading: true,
      socket: null,
      messages: [],
      newMessage: '',
      search: '',
      games: [],
      ping: 0,
      sentTime: null,
      waitingToStart: false,
      waitingTime: 0,
      filteredGames: [],
      selectedIndex: -1,
      currentGame: "",
      boxArtUrl: "",
      userScore: 0,
      userScores: {},
      audioSource: '',
      audioDuration: 45, // durée par défaut de l'audio
      audioTimer: null,
      responses: 0,
      volume: 100,
      initialTime: 45,
      remainingTime: 45,
      startTime: null,
      responseTime: null,
      responseTimes: [],
      fastestTime: null,
      fastestResponseForGame: null,
      username: "",
      alreadyFastest: false,
      showPlayButton: true,
      showVolumeSlider: false,
      isFinished: false,
      topScorers: [],
      currentSong: 1,
      composer: "Unknown",
      music_title: "Unknown",
      scrollingInterval: null,
      maxTries: 3,
      song_id: null,
      showSettingsModal: false
    };
  },
  computed: {
    timeBarWidth() {
      if (!this.initialTime) return 0;
      const ratio = this.remainingTime / this.initialTime;
      const percent = Math.max(0, ratio * 100);
      return percent;
    },
    timeBarColor() {
      if (this.timeBarWidth > 50) {
        return 'green';
      } else if (this.timeBarWidth > 25) {
        return 'orange';
      } else {
        return 'red';
      }
    },
    sortedScores() {
      const baseList = Object.entries(this.userScores)
          .map(([username, data]) => ({
            username,
            score: data.score
          }))
          .sort((a, b) => b.score - a.score);

      // Ajout d'un champ rank (1, 2, 3, etc.)
      return baseList.map((player, idx) => {
        player.rank = idx + 1;
        return player;
      });
    }
  },
  created() {
    this.username = this.getUsernameFromCookie();
    this.initializeWebSocket();
    this.initializeRoomData();

    this.socket.on('settings_updated', (data) => {
      if (data.room_id === this.roomId) {
        console.log('Room settings updated, reloading...');
        this.fetchRoomDetails();
      }
    });

    this.socket.on('play_audio', this.handlePlayAudio);
    this.socket.on('prepare_to_play', this.handlePrepareToPlay);
    this.socket.on('sync_audio', this.handleSyncAudio);
    this.socket.on('score_update', () => {
      this.fetchScores();
    });
    this.socket.on('finished', () => {
      this.setPlay();
    });
    this.socket.on('details_set', () => {
      this.fetchGames();
    });
    this.socket.on('update_currentSong', (data) => {
      this.currentSong = data.index;
    });

    window.addEventListener('popstate', this.handleBackButton);
  },
  mounted() {
    if (sessionStorage.getItem('isPageRefreshed')) {
      sessionStorage.removeItem('isPageRefreshed');
      this.$router.push('/');
    }
  },
  methods: {
    handleReconnexion() {
      // Émettre la demande de synchronisation
      this.socket.emit('request_audio_sync', {
        room_id: this.roomId
      });
    },
    openSettings() {
      this.showSettingsModal = true;
    },
    closeSettings() {
      this.showSettingsModal = false;
    },
    saveSettings() {
      apiClient.post(
          `/api/update_room_settings/${this.roomId}?username=${this.username}`,
          {
            numberOfSongs: this.roomDetails.numberOfSongs,
            includeGames: this.roomDetails.includeGames,
            includeAnimes: this.roomDetails.includeAnimes,
            includeIGDBList: this.roomDetails.includeIGDBList,
            includeAnimeList: this.roomDetails.includeAnimeList
          }
      )
          .then(response => {
            console.log('Settings updated successfully:', response.data);
            this.closeSettings();
          })
          .catch(error => {
            console.error('Error updating settings:', error);
          });
    },
    toggleVolumeSlider() {
      this.showVolumeSlider = !this.showVolumeSlider;
    },
    getPlayerClass(player) {
      const userData = this.userScores[player.username];
      if (!userData) return '';
      if (userData.hasFound) {
        return 'found';
      } else if (userData.attemptsLeft === 0) {
        return 'failed';
      } else {
        return 'unfound';
      }
    },
    handleEndOfGame() {
      this.fetchFastestTimeInSession();
      setTimeout(() => {
        this.socket.emit('client_ready', { room_id: this.roomId, song_id: this.song_id });
      }, 5000);
    },
    initializeWebSocket() {
      this.socket = io('https://avgmq.net', { secure: true });

      this.socket.on('server_time', (data) => {
        const serverMs = data.server_time;
        const clientMs = Date.now(); // Horloge locale
        this.clockOffset = clientMs - serverMs;
        console.log(`Clock offset: ${this.clockOffset} ms`);
      });

      this.socket.on('connect', () => {
        console.log('Connected to the game server');
        this.socket.emit('joinPrivateRoom', { username: this.username, room: this.roomId });
        this.handleReconnexion();
        
        setInterval(() => {
          this.sentTime = Date.now();
          this.socket.emit('ping_request', { sent_time: this.sentTime });
        }, 5000);

        this.socket.on('ping_response', () => {
          if (this.sentTime) { // Vérifier que sentTime a été défini
            const fullRoundTrip = Date.now() - this.sentTime;
            this.ping = fullRoundTrip;
            console.log(`Ping: ${this.ping} ms`);
            this.sentTime = null; // Réinitialiser sentTime
          } else {
            console.warn('Received ping_response without a matching sentTime.');
          }
        });
      });

      this.socket.on('message', (message) => {
        this.messages.push({
          id: message.id,
          content: message.content,
          username: message.username
        });
      });

      this.socket.on('disconnect', () => {
        console.log('Disconnected from the game server');
      });
    },
    async initializeRoomData() {
      try {
        await this.fetchRoomDetails();
        this.fetchGames();
      } catch (error) {
        console.error('Error initializing room data:', error);
      }
    },
    async fetchRoomDetails() {
      try {
        const response = await apiClient.get(
            `/api/room_details/${this.roomId}?username=${this.username}`
        );
        this.roomDetails = response.data;
      } catch (error) {
        console.error('Error fetching room details:', error);
      }
    },
    fetchScores() {
      apiClient.get(
          `/api/get_scores/${this.roomId}`
      )
          .then(response => {
            this.userScores = response.data;
            if (this.username in response.data) {
              const userAttemptsLeft = response.data[this.username].attemptsLeft;
              this.responses = this.maxTries - userAttemptsLeft;
            } else {
              console.warn(`[WARN] User ${this.username} not found in the scores.`);
              this.responses = this.maxTries; // Valeur par défaut si non trouvé
            }
          })
          .catch(error => {
            console.error('Error fetching scores:', error);
          });
    },
    setPlay() {
      apiClient.get(
          `/api/get_top_scorers/${this.roomId}`
      )
          .then(response => {
            this.isFinished = true;
            this.topScorers = response.data;
            this.showPlayButton = true;
          })
          .catch(error => {
            console.error('Error getting top scorers:', error);
          });
    },
    playMusic() {
      this.showPlayButton = false;
      this.isFinished = false;
      this.topScorers = [];
      this.alreadyFastest = false;

      for (const username in this.userScores) {
        this.userScores[username].score = 0;
        this.userScores[username].hasFound = false;
        this.userScores[username].attemptsLeft = 3;
        this.userScores[username].isFastest = false;
        this.userScores[username].responseTime = null;
      }

      apiClient.post(
          `/api/set_scores/${this.roomId}`,
          {
            scores: this.userScores,
            room: this.roomId,
            username: this.username,
            relaunch: true
          }
      )
          .then(response => {
            console.log('Scores updated successfully:', response.data);
          })
          .catch(error => {
            console.error('Error updating scores:', error);
          });

      this.socket.emit('play', {
        room_id: this.roomId,
        numberOfSongs: this.roomDetails.numberOfSongs,
        games: this.roomDetails.includeGames,
        animes: this.roomDetails.includeAnimes,
        IGDB: this.roomDetails.includeIGDBList,
        AnimeList: this.roomDetails.includeAnimeList
      });
    },
    fetchFastestTimeInSession() {
      if (this.userScores && Object.keys(this.userScores).length > 0) {
        const fastestUser = Object.entries(this.userScores)
            .filter(([, data]) => data.responseTime !== null && data.responseTime !== undefined)
            .sort(([, a], [, b]) => a.responseTime - b.responseTime)[0];

        if (fastestUser) {
          const [username, data] = fastestUser;
          this.fastestTime = `${username}: ${data.responseTime.toFixed(3)} seconds`;
        } else {
          this.fastestTime = "No fastest response in this session yet.";
        }
      } else {
        this.fastestTime = "No scores available in this session yet.";
      }
    },
    async fetchFastestResponse(gameName) {
      try {
        const encodedGameName = encodeURIComponent(gameName);
        const response = await apiClient.get(`/api/get_fastest_response/${encodedGameName}`);
        if (response.status === 200) {
          const { username, response_time } = response.data;
          this.fastestResponseForGame = `${username}: ${response_time.toFixed(3)} seconds`;
        } else {
          this.fastestResponseForGame = "No fastest response recorded for this game.";
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.fastestResponseForGame = "No fastest response recorded for this game.";
        } else {
          console.error('Error fetching fastest response:', error);
          this.fastestResponseForGame = "Error fetching fastest response.";
        }
      }
    },
    updateVolume() {
      let player = this.$refs.audioPlayer;
      if (player) {
        player.volume = this.volume / 100;
      }
    },
    handlePlayAudio(data) {
      this.currentGame = data.game_name;
      this.song_id = data.song_id;
      this.fetchFastestResponse(this.currentGame);
      this.fastestTime = "No time recorded";
      this.audioSource = data.audio_url;
      this.remainingTime = data.duration;
      this.startTime = performance.now();
      this.responses = 0;
      this.alreadyFastest = false;
      this.boxArtUrl = data.box_art_url;
      this.composer = data.composer;
      this.music_title = data.music_title;

      // Reset du scoreboard pour la nouvelle musique
      for (const username in this.userScores) {
        this.userScores[username].hasFound = false;
        this.userScores[username].attemptsLeft = 3;
        this.userScores[username].isFastest = false;
        this.userScores[username].responseTime = null;
      }

      apiClient.post(
          `/api/set_scores/${this.roomId}`,
          {
            scores: this.userScores,
            room: this.roomId,
            username: this.username,
            relaunch: false
          }
      )
          .then(response => {
            console.log('Scores updated successfully:', response.data);
          })
          .catch(error => {
            console.error('Error updating scores:', error);
          });
      
      const player = this.$refs.audioPlayer;
      player.src = this.audioSource;  // Assurez-vous que cela pointe vers l'URL correcte
      player.load(); // Charger la nouvelle source
      player.currentTime = data.start_time;

      player.play().then(() => {
        if (this.audioTimer) {
          clearInterval(this.audioTimer);
        }
        this.audioTimer = setInterval(() => {
          if (this.remainingTime > 0) {
            this.remainingTime -= 1;
          } else {
            clearInterval(this.audioTimer);
            this.responses = 3;
            player.pause();
            this.handleEndOfGame();
          }
        }, 1000);
      }).catch(error => {
        console.error("Error playing audio:", error);
      });
    },
    handlePrepareToPlay(data) {
      const { audio_url, start_time, play_at, game_name, music_title, composer, duration } = data;
      this.currentGame = game_name;
      this.song_id = data.song_id;
      this.fetchFastestResponse(this.currentGame);
      this.fastestTime = "No time recorded";
      this.audioSource = audio_url;
      this.music_title = music_title;
      this.composer = composer;
      this.remainingTime = duration;
      this.startTime = performance.now();
      this.responses = 0;
      this.boxArtUrl = data.box_art_url;
      this.alreadyFastest = false;

      for (const username in this.userScores) {
        this.userScores[username].hasFound = false;
        this.userScores[username].attemptsLeft = 3;
        this.userScores[username].isFastest = false;
        this.userScores[username].responseTime = null;
      }

      const player = this.$refs.audioPlayer;
      if (player) {
        player.src = audio_url;
        player.load();

        const currentTime = Date.now() / 1000;
        const delay = play_at + this.clockOffset - currentTime;

        if (delay > 0) {
          this.waitingToStart = true;
          this.waitingTime = delay;
          console.log(`Audio will start in ${delay} seconds`);

          const countdownInterval = setInterval(() => {
            this.waitingTime -= 1;
            if (this.waitingTime <= 0) {
              clearInterval(countdownInterval);
              this.waitingToStart = false;
            }
          }, 1000);

          setTimeout(() => {
            this.waitingToStart = false;
            player.currentTime = start_time;
            player.play().then(() => {
              console.log('Audio is playing');
              this.startAudioTimer(duration);
            }).catch((error) => {
              console.error('Error playing audio:', error);
            });
          }, delay * 1000);
        } else {
          console.error("Audio started late, playing immediately.");
          player.currentTime = start_time;
          player.play();
          this.startAudioTimer(duration);
        }
      }
    },
    startAudioTimer(duration) {
      this.remainingTime = duration;
      if (this.audioTimer) {
        clearInterval(this.audioTimer);
      }
      this.audioTimer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime -= 1;
        } else {
          clearInterval(this.audioTimer);
          this.responses = 3;
          this.$refs.audioPlayer.pause();
          this.handleEndOfGame();
        }
      }, 1000);
    },
    handleSyncAudio(data) {
      this.showPlayButton = false;
      this.song_id = data.song_id;
      // 1. Vérifier qu'on a bien un lecteur audio référencé
      const player = this.$refs.audioPlayer;
      if (!player) {
        console.error('Audio player non disponible dans $refs.');
        return;
      }

      // 2. Mettre à jour l'état local (si vous avez des variables dans data())
      //    Par exemple, afficher le titre, le compositeur, etc.
      this.currentGame = data.game_name || 'Unknown Game';
      this.audioSource = data.audio_url || '';
      this.boxArtUrl = data.box_art_url || '';
      this.composer = data.composer || 'Unknown';
      this.music_title = data.music_title || 'Unknown';

      // 3. Calculer le temps restant si vous en avez besoin

      // a) Convertir start_timestamp du serveur en ms (ou vice-versa)
      const serverStartMs = data.start_timestamp * 1000; // car time.time() = secondes, et Date.now() = millisecondes

      // b) Ajuster avec l'offset pour avoir "localStartMs"
      //    localStartMs = moment où la lecture a commencé en horloge locale
      const localStartMs = serverStartMs + this.clockOffset;

      // c) Le temps actuel local en ms
      const nowMs = Date.now();

      // d) Temps écoulé depuis le début de la musique
      const elapsedMs = nowMs - localStartMs;
      const elapsedSec = elapsedMs / 1000;

      // e) S'il y a déjà plus que data.duration ? => on met 0
      let remaining = data.duration - elapsedSec;
      if (remaining < 0) {
        remaining = 0;
      }
      this.remainingTime = remaining;


      // 4. Charger l'audio dans le <audio> et se positionner à la bonne seconde
      player.src = this.audioSource;
      player.load();

      // 5. Se positionner dans l'audio
      //    Si elapsedSec = 13, c'est qu'on est à la 13e seconde (sauf si > 45)
      if (elapsedSec >= data.duration) {
        // La musique est techniquement terminée
        player.currentTime = data.duration;
        this.remainingTime = 0;
      } else if (elapsedSec > 0) {
        player.currentTime = elapsedSec;
      } else {
        player.currentTime = 0;
      }

      // 6. (Re)lancer la lecture
      player.play().then(() => {
        console.log('Audio synchronized and playing at', elapsedSec, 'seconds');
        // Lancer un timer local
        this.startAudioTimer(this.remainingTime);
      })
          .catch((err) => {
            console.error('Error playing audio:', err);
          });
    },
    handleKeyUp() {
      if (this.scrollingInterval) {
        clearInterval(this.scrollingInterval);
        this.scrollingInterval = null;
      }
    },
    onArrowDown() {
      if (this.selectedIndex < this.filteredGames.length - 1) {
        this.selectedIndex++;
        this.scrollToSelected();
      }
    },
    onArrowUp() {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
        this.scrollToSelected();
      }
    },
    scrollToSelected() {
      this.$nextTick(() => {
        const listItems = this.$el.querySelectorAll('.sugest li');
        if (listItems[this.selectedIndex]) {
          listItems[this.selectedIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        }
      });
    },
    onTab() {
      if (this.selectedIndex >= 0 && this.selectedIndex < this.filteredGames.length) {
        this.selectGame(this.filteredGames[this.selectedIndex]);
      }
    },
    validateInput() {
      this.checkGameSelection(this.search);
      this.search = '';
      this.filteredGames = [];
      this.selectedIndex = -1;
    },
    selectGame(gameName) {
      this.search = gameName;
      this.filteredGames = [];
      this.selectedIndex = -1;
    },
    checkGameSelection(selectedGame) {
      const inputElement = this.$refs.inputElement;
      if (this.responses < 3) {
        if (selectedGame.toLowerCase() === this.currentGame.toLowerCase()) {
          this.responses = 3;
          this.userScores[this.username].hasFound = true;
          this.userScores[this.username].attemptsLeft = 0;
          inputElement.style.backgroundColor = 'green';

          const responseTime = (performance.now() - this.startTime) / 1000;
          this.userScores[this.username].responseTime = responseTime;
          this.fetchFastestTimeInSession();

          apiClient.post('/api/update_fastest_response', {
            game_name: this.currentGame,
            username: this.username,
            response_time: responseTime
          })
              .then(() => {
                console.log('Fastest response checked/updated in the database.');
              })
              .catch(error => {
                console.error('Error updating fastest response:', error);
              });

          setTimeout(() => {
            inputElement.style.backgroundColor = '';
          }, 500);

          this.updateUserScore(this.username);
        } else {
          this.responses++;
          this.userScores[this.username].attemptsLeft -= 1;

          inputElement.style.backgroundColor = 'red';
          setTimeout(() => {
            inputElement.style.backgroundColor = '';
          }, 500);

          apiClient.post(
              `/api/set_scores/${this.roomId}`,
              {
                scores: this.userScores,
                room: this.roomId,
                username: this.username,
                relaunch: false
              }
          );
        }
      }
    },
    canUserRespond() {
      return this.userScores[this.username] && this.userScores[this.username].attemptsLeft > 0;
    },
    updateUserScore(username) {
      if (this.userScores[username]) {
        this.userScores[username].score++;
      } else {
        this.userScores[username].score = 1;
      }

      apiClient.post(
          `/api/set_scores_up/${this.roomId}`,
          {
            scores: this.userScores,
            room: this.roomId,
            username: this.username
          }
      )
          .then(response => {
            console.log('Scores updated successfully:', response.data);
          })
          .catch(error => {
            console.error('Error updating scores:', error);
          });
    },
    getUsernameFromCookie() {
      const value = `; ${document.cookie}`;
      const parts = value.split('; username=');
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
      return "";
    },
    fetchGames() {
      let apiEndpoint;
      console.log('room details', this.roomDetails);
      if (this.roomDetails.includeGames && this.roomDetails.includeAnimes) {
        apiEndpoint = 'api/all';
      } else if (this.roomDetails.includeAnimes) {
        apiEndpoint = 'api/animes';
      } else {
        apiEndpoint = 'api/games';
      }

      if (apiEndpoint) {
        this.fetchData(apiEndpoint);
      }
    },
    fetchData(endpoint) {
      apiClient.get(endpoint)
          .then((response) => {
            if (Array.isArray(response.data)) {
              const gameNames = response.data
                  .map(game => {
                    if (typeof game === 'string') {
                      return game.split(',')[0];
                    } else {
                      return game[0].split(',')[0];
                    }
                  })
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .sort();
              this.games = gameNames;
            } else {
              console.error('Les données reçues ne sont pas un tableau', response.data);
            }
          })
          .catch((error) => {
            console.error(`Erreur lors de la récupération depuis ${endpoint}`, error);
          });
    },
    normalizeString(str) {
      return str.normalize("NFD").replace(/[̀-ͯ]/g, "");
    },
    filterGames() {
      if (this.search) {
        const normalizedSearch = this.normalizeString(this.search.toLowerCase());
        this.filteredGames = this.games.filter((gameName) => {
          const normalizedGameName = this.normalizeString(gameName.toLowerCase());
          return normalizedGameName.includes(normalizedSearch);
        });
      } else {
        this.filteredGames = [];
      }
    },
    playTimer(elapsedTime, duration) {
      this.remainingTime = duration - elapsedTime;
      if (this.audioTimer) {
        clearInterval(this.audioTimer);
      }
      this.audioTimer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime -= 1;
        } else {
          clearInterval(this.audioTimer);
          this.$refs.audioPlayer.pause();
          this.handleEndOfGame();
        }
      }, 1000);
    },
    extractVideoIdFromUrl(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      if (match && match[2].length === 11) {
        return match[2];
      } else {
        console.error('Invalid YouTube URL:', url);
        return null;
      }
    },
    sendMessage() {
      if (this.newMessage.trim()) {
        this.socket.emit('newMessage', { content: this.newMessage, username: this.username });
        this.newMessage = '';
      }
    },
    handleBackButton() {
      this.logout(() => {
        console.log('User logged out');
      });
    },

  }
};

</script>

<style scoped>
/* ***** STYLES IDENTIQUES À CEUX DONNÉS, PAS MODIFIÉS ***** */
.room-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

/* Top Bar for Volume and Song Info */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
}

.volume-wrapper {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.volume-icon {
  font-size: 18px;
  color: white;
}

.volume-slider-container {
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.5);
}

.volume-slider-container input[type="range"] {
  width: 120px;
}

/* Simple fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.song-info {
  font-size: 14px;
  color: white;
}

/* Room Title */
.room-title {
  text-align: center;
  margin-top: 20px;
}

.room-title .room-name {
  color: #4caf50;
  font-weight: bold;
}

.room-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
}

.play-button,
.settings-button {
  /* Couleurs de fond : un joli vert dégradé */
  background: linear-gradient(135deg, #28a745 0%, #4caf50 100%);
  /* Ajuster l'espacement et le rayon des bords */
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  /* Couleur et style de police */
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  /* Petite ombre pour le relief */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  /* Transition fluide sur le hover */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin: 5px;
}

.play-button:hover,
.settings-button:hover {
  /* Légère élévation au survol */
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

/* Éventuellement, on peut changer la couleur au clic */
.play-button:active,
.settings-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
}

/* Si vous voulez une icône devant le texte */
.play-button::before {
  content: "▶ ";
  /* Ou pour Settings : content: "⚙  "; */
}

/* Variation si vous voulez distinguer un peu les deux boutons */
.settings-button {
  background: linear-gradient(135deg, #388e3c 0%, #4caf50 100%);
}


/* Left Section: Scores */
.left-section {
  position: absolute;
  margin-top: 20px;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  height: 60%;
  background-color: rgba(50, 50, 50, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.scores-title {
  margin-bottom: 20px;
  font-size: 2rem;
  color: white;
  text-align: center;
}

.scoreboard-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  color: white;
}

.scoreboard-table thead {
  background-color: #333;
}

.scoreboard-table th,
.scoreboard-table td {
  padding: 10px 15px;
  text-align: left;
}

.scoreboard-table tbody tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

.scoreboard-table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@keyframes scoreBlink {
  0% { background-color: green; }
  100% { background-color: transparent; }
}

.score-animated {
  animation: scoreBlink 1s ease-out forwards;
}


.currentUser {
  background-color: rgba(0, 255, 100, 0.2);
}

.crown {
  color: gold;
  margin-left: 8px;
  font-size: 1.2rem;
}

.found {
  color: green;
}

.unfound {
  color: yellow;
}

.failed {
  color: red;
}

/* Right Section: Response */
.right-section {
  position: absolute;
  margin-top: 20px;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  height: 60%;
  background-color: rgba(50, 50, 50, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.response-block {
  text-align: center;
  width: 100%;
}

.inputResponse {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #222;
  color: white;
}

.inputResponse:disabled {
  background: #555;
}

/* Response Details */
.response-details {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(50, 50, 50, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.box-art {
  margin-top: 10px;
  max-width: 200px;
  border-radius: 8px;
}

/* Hidden Audio Player */
.hidden {
  display: none;
}

/* Settings Modal */
.settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(50, 50, 50, 0.9);
  color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.settings-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.settings-content button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.settings-content button:hover {
  background-color: #45a049;
}

.sugest {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: rgba(50, 50, 50, 0.9);
  border: 1px solid #666;
  border-radius: 4px;
}

.sugest li {
  padding: 10px;
  background: rgba(50, 50, 50, 0.8);
  color: white;
  cursor: pointer;
  border-bottom: 1px solid #444;
}

.sugest li:last-child {
  border-bottom: none;
}

.sugest li.selected {
  background: #4caf50;
  color: black;
  font-weight: bold;
}

.time-container {
  width: 80%;
  margin: 0 auto;
  height: 20px;
  background: #333;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.time-bar {
  height: 100%;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 20px;
  transition: width 0.4s ease-out, background-color 0.4s ease-out;
}

.heart {
  font-size: 1.5rem;
  color: red;
  margin-right: 5px;
}

.found {
  background-color: rgba(0, 255, 0, 0.1);
}
.unfound {
  background-color: rgba(255, 165, 0, 0.1);
}
.failed {
  background-color: rgba(255, 0, 0, 0.1);
}

/* Responsive (max-width: 768px) */
@media (max-width: 768px) {
  .room-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .left-section,
  .right-section {
    position: static !important;
    transform: none !important;
    width: 100%;
    max-width: 90%;
    padding: 15px;
    background-color: rgba(50, 50, 50, 0.9);
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    flex-grow: 1;
    min-height: 150px;
    max-height: 40%;
  }

  .left-section {
    order: 1;
    margin-bottom: 25px;
  }

  .right-section {
    order: 2;
    margin-top: 25px;
  }

  .response-block {
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
  }

  .inputResponse {
    width: calc(100% - 20px);
    margin: 10px auto;
  }
}

.left-section,
.right-section {
  box-sizing: border-box;
  height: auto;
  overflow-y: auto;
  padding: 20px;
}

.scores-title,
.response-block p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.inputResponse {
  margin-top: 5px;
}
</style>
