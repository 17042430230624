<template>
  <div class="create-room-container">
    <div class="create-room-card">
      <h1 class="create-room-title">Create Private Room</h1>
      <form @submit.prevent="createRoom" class="form-container">
        <input
            v-model="roomDetails.name"
            type="text"
            placeholder="Room Name"
            required
        />
        <input
            v-model="roomDetails.password"
            type="password"
            placeholder="Password"
            required
        />
        <input
            v-model.number="roomDetails.numberOfSongs"
            type="number"
            placeholder="Number of Songs"
            required
        />
        <label class="checkbox-container">
          <input v-model="roomDetails.includeGames" type="checkbox" />
          <span>Include Games</span>
        </label>
        <label class="checkbox-container">
          <input v-model="roomDetails.includeAnimes" type="checkbox" />
          <span>Include Animes</span>
        </label>
        <label class="checkbox-container">
          <input v-model="roomDetails.includeIGDBList" type="checkbox" />
          <span>Use Game List</span>
        </label>
        <label class="checkbox-container">
          <input v-model="roomDetails.includeAnimeList" type="checkbox" />
          <span>Use Anime List</span>
        </label>
        <button type="submit" class="submit-btn">Create Room</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>
    </div>
  </div>
</template>


<script>
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    export default {
        data() {
            return {
                roomDetails: {
                    name: '',
                    password: '',
                    username:'',
                    numberOfSongs: '',
                    includeGames: true,
                    includeAnimes: false,
                    includeIGDBList: false,
                    includeAnimeList: false
                },
                errorMessage: ''  // pour stocker les messages d'erreur
            };
        },
        methods: {
            getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
                return '';
            },

            createRoom() {
                const username = this.getCookie('username');  // Récupération du nom d'utilisateur depuis les cookies
                if (!username) {
                    this.errorMessage = 'You must be logged in to create a room.';
                    return;
                }

                this.roomDetails.username = username;  // Ajout de l'username aux roomDetails
                this.errorMessage = '';  // Réinitialiser le message d'erreur

                apiClient.post('/api/private_rooms', this.roomDetails)
                    .then(response => {
                        console.log('Room Created:', response.data);
                        this.$router.push({ name: 'PrivateRoom', params: { roomId: response.data.room_id } });
                    })
                    .catch(error => {
                        console.error('Error creating room:', error);
                        this.errorMessage = 'Failed to create room. Please check your input and try again.';
                    });
            }
        }
    };

</script>

<style scoped>
/* Conteneur principal */
.create-room-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
  url('@/assets/background.jpg') no-repeat center center fixed;
  background-size: cover;
}

@media (min-aspect-ratio: 21/9) {
  .create-room-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url('@/assets/background_2.jpg') no-repeat center center fixed;
  }
}

/* Carte centrale */
.create-room-card {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px 50px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  max-width: 400px;
  width: 100%;
}

/* Titre */
.create-room-title {
  font-size: 2rem;
  font-weight: 600;
  color: #4caf50;
  margin-bottom: 20px;
}

/* Formulaire */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Champs de saisie */
input[type="text"],
input[type="password"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: border 0.3s ease, background-color 0.3s ease;
  outline: none;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

input:focus {
  border-color: #4caf50;
  background: rgba(255, 255, 255, 0.2);
}

/* Cases à cocher */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.checkbox-container input {
  width: 16px;
  height: 16px;
  accent-color: #4caf50; /* Change la couleur de la case cochée */
}

/* Bouton */
.submit-btn {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 10px 0;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

/* Messages d'erreur */
.error-message {
  color: #ff6b6b;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Animation fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>