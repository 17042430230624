<template>
  <div class="register-container">
    <div class="register-card">
      <h1 class="register-title">Registration</h1>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="username">Username</label>
          <input
              type="text"
              id="username"
              v-model="user.username"
              placeholder="Enter your username"
              required
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
              type="password"
              id="password"
              v-model="user.password"
              placeholder="Enter your password"
              required
          />
        </div>
        <button type="submit" class="register-btn">Register</button>
      </form>
    </div>
  </div>
</template>


<script>
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true, // Enable sending cookies over CORS
        headers: {
            'Content-Type': 'application/json',
            // Vous pouvez ajouter ici des en-têtes ou des configurations supplémentaires
        },
    });

    export default {
        data() {
            return {
                user: {
                    username: '',
                    password: '',
                },
            };
        },
        methods: {
            async register() {
                try {
                    const response = await apiClient.post('/api/register', this.user);
                    // Gérer la réponse, par exemple stocker le JWT et rediriger l'utilisateur
                    localStorage.setItem('access_token', response.data.access_token);
                    this.$router.push({ name: 'RoomSelection' }); // ou vers une autre route
                    document.cookie = "username=" + this.user.username + ";path=/;max-age=86400"; // Expire après 1 heure
                } catch (error) {
                    console.error(error);
                    // Gérer les erreurs, par exemple afficher un message à l'utilisateur
                }
            },
        },
    };
    
</script>

<style scoped>
/* Global container centré */
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
  url('@/assets/background.jpg') no-repeat center center fixed;
  background-size: cover;
}

@media (min-aspect-ratio: 21/9) {
  .register-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url('@/assets/background_2.jpg') no-repeat center center fixed;
  }
}

/* Carte principale */
.register-card {
  background: rgba(0, 0, 0, 0.6); /* Fond semi-transparent */
  padding: 40px 50px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  width: 350px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Titre */
.register-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #4caf50;
}

/* Champs de saisie */
.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease;
  outline: none;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

input:focus {
  border-color: #4caf50;
  background: rgba(255, 255, 255, 0.2);
}

/* Bouton stylisé */
.register-btn {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.register-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
