<template>
  <div class="room-selection-container">
    <div class="room-selection-card">
      <h1 class="room-title">Select a Room</h1>
      <h2 class="section-title">Normal Rooms</h2>
      <div class="button-group">
        <button @click="goToRoom('GameRoom')" class="room-btn">Game Room</button>
        <button @click="goToRoom('AllRoom')" class="room-btn">All Room</button>
        <button @click="goToRoom('AnimeRoom')" class="room-btn">Anime Room</button>
      </div>

      <h2 class="section-title">Private Rooms</h2>
      <div class="button-group">
        <button @click="goToRoom('CreatePrivateRoom')" class="room-btn">Host</button>
        <button @click="goToRoom('JoinPrivateRoom')" class="room-btn">Join</button>
      </div>

      <h2 class="section-title">Settings</h2>
      <div class="button-group">
        <button @click="goToRoom('UserSettings')" class="room-btn">Settings</button>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        methods: {
            goToRoom(roomName) {
                console.log(roomName);
                this.$router.push({ name: roomName });
            },
        },
    };
</script>

<style scoped>

/* Conteneur principal */
.room-selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
  url('@/assets/background.jpg') no-repeat center center fixed;
  background-size: cover;
}

@media (min-aspect-ratio: 21/9) {
  .room-selection-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url('@/assets/background_2.jpg') no-repeat center center fixed;
  }
}

/* Carte centrale */
.room-selection-card {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px 50px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  max-width: 400px;
  width: 100%;
}

/* Titre principal */
.room-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #4caf50;
  margin-bottom: 20px;
}

/* Titres de section */
.section-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #81c784;
  margin: 20px 0 10px;
  text-transform: uppercase;
}

/* Groupe de boutons */
.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

/* Boutons */
.room-btn {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.room-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

/* Animation fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
