<template>
  <div class="user-settings-container">
    <div class="user-settings-card">
      <h1 class="title">User Settings</h1>

      <!-- Conteneur pour jeux et animes -->
      <div class="content-container">
        <!-- Liste des jeux -->
        <div class="games-list-container">
          <h2>Games</h2>
          <div class="games-list">
            <form @submit.prevent="saveSelectedGames" class="form-container">
              <div v-for="game in games" :key="game.id" class="game-option">
                <!-- Image de la jaquette -->
                <img
                    v-if="game.cover_url"
                    :src="game.cover_url"
                    :alt="game.name"
                    class="game-cover"
                />
                <!-- Checkbox et étiquette -->
                <div class="game-details">
                  <input
                      type="checkbox"
                      :id="'game_' + game.id"
                      :value="game.id"
                      v-model="selectedGames"
                      class="styled-checkbox"
                  />
                  <label :for="'game_' + game.id" class="game-label">
                    {{ getFirstGameName(game.name) }}
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Liste des animes -->
        <div class="animes-list-container">
          <h2>Animes</h2>
          <div class="animes-list">
            <form @submit.prevent="saveSelectedAnimes" class="form-container">
              <div v-for="anime in animes" :key="anime.id" class="anime-option">
                <!-- Image de la jaquette -->
                <img
                    v-if="anime.cover_url"
                    :src="anime.cover_url"
                    :alt="anime.name"
                    class="anime-cover"
                />
                <!-- Checkbox et étiquette -->
                <div class="anime-details">
                  <input
                      type="checkbox"
                      :id="'anime_' + anime.id"
                      :value="anime.id"
                      v-model="selectedAnimes"
                      class="styled-checkbox"
                  />
                  <label :for="'anime_' + anime.id" class="anime-label">
                    {{ getFirstAnimeName(anime.name) }}
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Boutons -->
      <button @click="saveSelections" class="save-btn">Save</button>
      <button @click="backToMenu" class="back-btn">Back to Menu</button>
    </div>
  </div>
</template>




<script>
import axios from 'axios';

export default {
  data() {
    return {
      games: [],
      selectedGames: [],
      animes: [], // Ajout pour les animes
      selectedAnimes: [], // Ajout pour les animes sélectionnés
    };
  },
  created() {
    this.fetchGames();
    this.fetchAnimes(); // Appel pour récupérer les animes
  },
  methods: {
    async fetchGames() {
      try {
        const username = this.getCookie('username');
        if (!username) {
          console.error("Nom d'utilisateur non trouvé dans les cookies.");
          return;
        }

        const response = await axios.get('/api/games_and_id');
        this.games = response.data;

        const selectedResponse = await axios.get('/api/user_selected_games', {
          params: { username },
        });
        this.selectedGames = selectedResponse.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des jeux:', error);
      }
    },
    async fetchAnimes() {
      try {
        const username = this.getCookie('username');
        if (!username) {
          console.error("Nom d'utilisateur non trouvé dans les cookies.");
          return;
        }

        const response = await axios.get('/api/animes_and_id'); // Endpoint pour les animes
        this.animes = response.data;

        const selectedResponse = await axios.get('/api/user_selected_animes', {
          params: { username },
        });
        this.selectedAnimes = selectedResponse.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des animes:', error);
      }
    },
    async saveSelectedGames() {
      try {
        const username = this.getCookie('username');
        if (!username) {
          alert("Nom d'utilisateur non trouvé dans les cookies.");
          return;
        }

        await axios.post('/api/user_igdb_lists', {
          username,
          game_ids: this.selectedGames,
        });
        alert('Games saved successfully!');
      } catch (error) {
        console.error("Erreur lors de l'enregistrement des jeux:", error);
        alert('An error occurred while saving your games.');
      }
    },
    async saveSelectedAnimes() {
      try {
        const username = this.getCookie('username');
        if (!username) {
          alert("Nom d'utilisateur non trouvé dans les cookies.");
          return;
        }

        await axios.post('/api/user_anime_lists', {
          username,
          anime_ids: this.selectedAnimes,
        });
        alert('Animes saved successfully!');
      } catch (error) {
        console.error("Erreur lors de l'enregistrement des animes:", error);
        alert('An error occurred while saving your animes.');
      }
    },
    async saveSelections() {
      // Sauvegarde des jeux et animes ensemble
      await this.saveSelectedGames();
      await this.saveSelectedAnimes();
    },
    getFirstGameName(name) {
      return name.split(',')[0];
    },
    getFirstAnimeName(anime) {
      return anime.split(",")[0];
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    backToMenu() {
      this.$router.push({ name: 'RoomSelection' });
    },
  },
};
</script>


<style scoped>
/* Conteneur principal */
.user-settings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
  url('@/assets/background.jpg') no-repeat center center fixed;
  background-size: cover;
}

@media (min-aspect-ratio: 21/9) {
  .user-settings-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)),
    url('@/assets/background_2.jpg') no-repeat center center fixed;
  }
}

/* Carte principale */
.user-settings-card {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  max-width: 1200px;
  width: 100%;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Titre principal */
.title {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #4caf50;
}

/* Conteneur pour les listes */
.content-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

/* Conteneur de la liste des jeux et des animes */
.games-list-container,
.animes-list-container {
  width: 45%;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Liste des jeux et des animes */
.games-list,
.animes-list {
  max-height: 600px;
  overflow-y: auto;
  margin-top: 10px;
}

/* Options de jeu et d'anime */
.game-option,
.anime-option {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.3s ease;
}

.game-option:hover,
.anime-option:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Images des jaquettes */
.game-cover,
.anime-cover {
  width: 80px;
  height: auto;
  border-radius: 8px;
  margin-right: 15px;
}

/* Détails des jeux et des animes */
.game-details,
.anime-details {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

/* Checkbox stylisée */
.styled-checkbox {
  width: 18px;
  height: 18px;
  accent-color: #4caf50; /* Couleur de la checkbox */
  cursor: pointer;
}

/* Étiquette des jeux et des animes */
.game-label,
.anime-label {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}

/* Boutons */
.save-btn,
.back-btn {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
  margin-top: 10px;
}

.save-btn:hover,
.back-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* Message de chargement */
.loading-message {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
}

/* Animation fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


</style>
