<template>
    <div class="game-room">
        <h1>Anime Room</h1>
        <div class="top-bar">
            <div class="scoreboard">
                <h2>Scores</h2>
                <ul>
                    <li v-for="(data, username) in userScores" :key="username" :class="userClasses[username]">
                        {{ data.level }} - {{ username }}: {{ data.score }}
                        <span v-if="data.isFastest" class="crown">👑</span>
                    </li>
                </ul>
            </div>
            <div class="volume-control">
                <label for="volumeSlider">Volume:</label>
                <input type="range" id="volumeSlider" min="0" max="100" v-model="volume" @input="updateVolume" class="volume-slider" />
            </div>
        </div>
        <div v-if="messages.length" class="messages">
            <ul>
                <li v-for="message in messages" :key="message.id">
                    <template v-if="message.username">
                        <strong>{{ message.username }}</strong> :&nbsp;
                    </template>
                    {{ message.content }}
                </li>
            </ul>
            <form @submit.prevent="sendMessage">
                <input v-model="newMessage" type="text" placeholder="Write your message..." />
            </form>
        </div>
        <audio ref="audioPlayer" id="audioPlayer1" style="display: none;" controls>
            <source :src="audioSource" type="audio/mpeg">
            Votre navigateur ne supporte pas l'élément audio.
        </audio>
        <div class="response" v-if="remainingTime <= 0">
            <h2>It was: {{ currentGame }}</h2>
        </div>
        <div class="timer" v-else>
            Remaining time : {{ Math.floor(remainingTime) }} seconds
        </div>
        <input type="text"
               class="inputResponse"
               ref="inputElement"
               :disabled="!canUserRespond()"
               v-model="search"
               @input="filterGames"
               @keydown.down.prevent="onArrowDown"
               @keydown.up.prevent="onArrowUp"
               @keyup.enter="validateInput"
               @keydown.tab.prevent="onTab"
               placeholder="Enter a game...">

        <ul class="sugest" v-if="filteredGames.length">
            <li v-for="(gameName, index) in filteredGames"
                :key="index"
                :class="{ 'selected': index === selectedIndex }"
                @click="selectGame(gameName)"
                @mouseenter="() => selectedIndex = index">
                {{ gameName }}
            </li>
        </ul>
        <div class="try">
            Remaining tries : {{ 3-responses }}
        </div>
    </div>
</template>

<script>
    import io from 'socket.io-client';
    import axios from 'axios';

    const apiClient = axios.create({
        baseURL: 'https://avgmq.net',
        withCredentials: true, // Enable sending cookies over CORS
        headers: {
            'Content-Type': 'application/json',
            // Vous pouvez ajouter ici des en-têtes ou des configurations supplémentaires
        },
    });

    export default {
        data() {
            return {
                socket: null,
                messages: [],
                newMessage: '',
                search: '',
                games: [],
                filteredGames: [],
                selectedIndex: -1,
                currentGame: "",
                userScore: 0,
                userScores: {},
                audioSource: '',
                audioDuration: 45, // durée par défaut pour jouer l'audio
                audioTimer: null,
                responses: 0,
                volume: 100,
                remainingTime: 45, // Initialisation à 45 secondes
                username: "",
            };
        },
        computed: {
            userClasses() {
                const classes = {};
                for (const [username, data] of Object.entries(this.userScores)) {
                    let className = '';
                    if (data.hasFound) {
                        className = 'found';
                    } else if (data.attemptsLeft === 0) {
                        className = 'failed';
                    } else {
                        className = 'unfound';
                    }
                    classes[username] = className;
                }
                return classes;
            }
        },
        beforeRouteLeave(to, from, next) {
            this.disconnectUser();  // Déconnectez l'utilisateur lors du changement de route
            next();
        },
        created() {
            this.initializeWebSocket();
            this.fetchGames();
            this.socket.on('play_audio', this.handlePlayAudio);
            this.socket.on('sync_audio', this.handleSyncAudio);
            this.socket.on('update_scores', (data) => {
                this.userScores = data.scores;
            });
            window.addEventListener('beforeunload', () => {
                if (this.socket) {
                    // Make sure to check if socket is connected before emitting
                    this.socket.emit('user_disconnecting', { username: this.username, room: 'AnimeRoom' });
                }
                sessionStorage.setItem('isPageRefreshed', 'true');
            });
        },
        mounted() {
            if (sessionStorage.getItem('isPageRefreshed')) {
                // Supprimez la marque indiquant que la page a été rafraîchie
                sessionStorage.removeItem('isPageRefreshed');

                // Redirigez l'utilisateur vers la page d'accueil
                this.$router.push('/');
            }
            window.addEventListener('popstate', () => {
                this.disconnectUser();  // Déconnectez l'utilisateur lorsqu'il utilise le bouton arrière
            });

        },
        beforeUnmount() {
            window.removeEventListener('popstate', this.disconnectUser);
            this.disconnectUser();  // Assurez-vous de déconnecter l'utilisateur quand le composant est détruit

            if (this.socket) {
                this.socket.disconnect(); // Disconnect the WebSocket when the component is destroyed
            }
            if (this.audioTimer) {
                clearInterval(this.audioTimer);
            }
        },
        methods: {
            initializeWebSocket() {
                this.socket = io('https://avgmq.net', { secure: true });

                this.socket.on('connect', () => {
                    console.log('Connected to the game server');
                    this.socket.emit('request_random_video');
                    this.username = this.getUsernameFromCookie()
                    this.socket.emit('join', { username: this.username, room: 'AnimeRoom' });
                });

                this.socket.on('message', (message) => {
                    this.messages.push({
                        id: message.id,
                        content: message.content,
                        username: message.username
                    });
                });

                this.socket.on('disconnect', () => {
                    this.socket.emit('user_disconnecting', { username: this.username, room: 'AnimeRoom' });
                    console.log('Disconnected from the game server');
                });
            },
            disconnectUser() {
                this.socket.emit('user_disconnecting', { username: this.username, room: 'AnimeRoom' });
                this.socket.disconnect();  // Envoyez un signal de déconnexion propre au serveur
            },
            updateVolume() {
                // Mettez à jour le volume de l'élément audio
                let player = this.$refs.audioPlayer;
                if (player) {
                    player.volume = this.volume / 100; // Le volume est un nombre entre 0 (silencieux) et 1 (volume maximum)
                }
            },
            handlePlayAudio(data) {
                this.currentGame = data.game_name;
                this.audioSource = data.audio_url;
                this.remainingTime = data.duration - data.elapsed_time;
                this.responses = 0;

                for (const username in this.userScores) {
                    this.userScores[username]['hasFound'] = false;
                    this.userScores[username]['attemptsLeft'] = 3;
                    this.userScores[username]['isFastest'] = false
                }
                this.socket.emit('no_attempts_left', { 'scores': this.userScores, 'room': 'AnimeRoom' });  
                this.$refs.audioPlayer.load();
                this.$refs.audioPlayer.currentTime = data.start_time;
                this.$refs.audioPlayer.play().then(() => {
                    if (this.audioTimer) {
                        clearInterval(this.audioTimer); // Assurez-vous de nettoyer le timer précédent s'il existe
                    }
                    this.audioTimer = setInterval(() => {
                        if (this.remainingTime > 0) {
                            this.remainingTime = this.remainingTime - 1;
                        } else {
                            clearInterval(this.audioTimer);
                            this.responses = 3;
                            this.$refs.audioPlayer.pause();
                        }
                    }, 1000); // Décrémente le compteur toutes les secondes
                }).catch(error => {
                    console.error("Error playing audio:", error);
                });
            },
            handleSyncAudio(data) {
                let remainingTime = data.duration - data.elapsed_time;
                if (remainingTime > 0) {
                    // Démarrez la lecture de l'audio avec le temps restant
                    this.playTimer(data.elapsed_time, data.duration);
                }
            },

            onArrowDown() {
                if (this.selectedIndex < this.filteredGames.length - 1) {
                    this.selectedIndex++;
                }
            },
            onArrowUp() {
                if (this.selectedIndex > 0) {
                    this.selectedIndex--;
                }
            },
            onTab() {
                // Assurez-vous que selectedIndex est dans la plage correcte
                if (this.selectedIndex >= 0 && this.selectedIndex < this.filteredGames.length) {
                    this.selectGame(this.filteredGames[this.selectedIndex]);
                }
            },
            validateInput() {
                // La méthode checkGameSelection est appelée avec la valeur actuelle de l'input
                this.checkGameSelection(this.search);
                // Réinitialiser le champ de recherche
                this.search = '';
                // Fermez la liste des suggestions
                this.filteredGames = [];
                // Réinitialisez l'index sélectionné
                this.selectedIndex = -1;
            },
            selectGame(gameName) {
                this.search = gameName;  // Met à jour le champ de recherche avec le nom du jeu sélectionné
                this.filteredGames = [];  // Efface les suggestions
                this.selectedIndex = -1;  // Réinitialise l'index sélectionné
            },
            checkGameSelection(selectedGame) {
                const inputElement = this.$refs.inputElement;
                if (this.responses < 3) {
                    if (selectedGame.toLowerCase() === this.currentGame.toLowerCase()) {
                        this.responses = 3;
                        this.userScores[this.username]['hasFound'] = true;
                        this.userScores[this.username]['attemptsLeft'] = 0;
                        inputElement.style.backgroundColor = 'green';
                        setTimeout(() => {
                            inputElement.style.backgroundColor = '';
                        }, 500);
                        this.updateUserScore(this.username);
                    } else {
                        this.responses++;
                        this.userScores[this.username]['attemptsLeft'] -= 1;

              
                        this.socket.emit('no_attempts_left', { 'scores': this.userScores, 'room': 'AnimeRoom' });
                        

                        inputElement.style.backgroundColor = 'red';
                        setTimeout(() => {
                            inputElement.style.backgroundColor = '';
                        }, 500);
                    }
                }
            },

            canUserRespond() {
                return this.userScores[this.username] && this.userScores[this.username]['attemptsLeft'] > 0;
            },

            updateUserScore(username) {
                if (this.userScores[username]) {
                    this.userScores[username]['scores']++;
                } else {
                    this.userScores[username]['scores'] = 1;
                }
                // Notifiez le serveur ou les autres utilisateurs si nécessaire
                this.socket.emit('increment_score', { 'username': username, 'points': 1, 'room': 'AnimeRoom' });

            },

            getUsernameFromCookie() {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; username=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
                return "";
            },
            fetchGames() {
                // Utilisez axios ou fetch pour récupérer les jeux de la DB et assignez-les à this.games
                // Exemple :
                apiClient.get('api/animes')
                    .then((response) => {
                      if (Array.isArray(response.data)) {
                        const gameNames = response.data
                            .map(game => typeof game === 'string' ? game.split(',')[0] : game[0].split(',')[0])  // Assume les noms peuvent venir dans un tableau ou comme chaîne
                            .filter((value, index, self) => self.indexOf(value) === index)  // Filtrer les doublons
                            .sort();
                        this.games = gameNames;
                      } else {
                        console.error('Les données reçues ne sont pas un tableau', response.data);
                      }
                    })
                    .catch((error) => {
                      console.error(`Erreur lors de la récupération des animes`, error);
                      // Afficher un message d'erreur à l'utilisateur si nécessaire
                    });
            },
            normalizeString(str) {
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            },

            filterGames() {
                if (this.search) {
                    const normalizedSearch = this.normalizeString(this.search.toLowerCase());
                    this.filteredGames = this.games.filter((gameName) => {
                        const normalizedGameName = this.normalizeString(gameName.toLowerCase());
                        return normalizedGameName.includes(normalizedSearch);
                    });
                } else {
                    this.filteredGames = [];
                }
            },

            playTimer(elapsedTime, duration) {

                this.remainingTime = duration - elapsedTime;

                if (this.audioTimer) {
                    clearInterval(this.audioTimer); // Assurez-vous de nettoyer le timer précédent s'il existe
                }
                this.audioTimer = setInterval(() => {
                    if (this.remainingTime > 0) {
                        this.remainingTime -= 1;
                    } else {
                        clearInterval(this.audioTimer);
                        this.$refs.audioPlayer.pause();
                    }
                }, 1000); // Décrémente le compteur toutes les secondes



            },


            extractVideoIdFromUrl(url) {
                // Utilisez une RegExp pour extraire l'ID de la vidéo
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = url.match(regExp);

                if (match && match[2].length == 11) {
                    return match[2];
                } else {
                    // L'URL n'est pas dans un format attendu pour une vidéo YouTube
                    console.error('Invalid YouTube URL:', url);
                    return null;
                }
            },
            sendMessage() {
                if (this.newMessage.trim()) {
                    this.socket.emit('newMessage', { content: this.newMessage, username: this.username });
                    this.newMessage = ''; // Reset the input field after sending
                }
            },

        }
    };
</script>


<style scoped>
    .game-room {
        position: relative;
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        text-align: center;
    }

    .messages {
        position: fixed;
        right: 0;
        top: 0;
        width: 300px; /* Définir une largeur pour la zone de message */
        height: 100vh; /* Prendre toute la hauteur de la vue */
        overflow-y: scroll; /* Permettre le défilement si le contenu est trop long */
        padding: 20px;
        background-color: rgb(18, 18, 18); /* Choisir une couleur de fond */
        border-left: 1px solid #ccc; /* Ajouter une bordure à gauche */
        box-shadow: -2px 0px 5px rgba(0,0,0,0.1); /* Ajouter une ombre pour mieux séparer du reste du contenu */
    }


        .messages ul {
            list-style: none;
            padding: 0;
        }

        .messages li {
            margin-bottom: 10px;
            background-color: rgb(36, 36, 36);
            color: white;
            padding: 10px;
            border-radius: 5px;
            word-wrap: break-word;
        }

        .messages input, .inputResponse {
            border: 1px solid #ccc;
            color: white;
        }

    ::placeholder {
        color: white;
    }

    input {
        margin-top: 20px;
        padding: 10px;
        width: calc(100% - 22px);
        margin-bottom: 10px;
    }

    .selected {
        background-color: #e0e0e0; /* ou toute autre couleur de votre choix */
    }

    .scoreboard {
        position: fixed; /* ou 'absolute' si vous préférez qu'il soit relatif au parent positionné */
        top: 0;
        left: 0;
        padding: 10px;
        background-color: rgb(18, 18, 18);
        border-right: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        margin-right: 50%;
        border-radius: 0px;
        z-index: 1000; /* Assurez-vous qu'il est au-dessus des autres éléments */
        width: auto; /* ou spécifiez une largeur si nécessaire */
        box-shadow: 0px 2px 5px rgba(0,0,0,0.1); /* Optionnel: ajoute de l'ombre pour le rendre plus visible */
    }

        .scoreboard h2 {
            font-size: 1.2rem;
            margin-bottom: 10px;
            text-align: center;
        }

        .scoreboard ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        .scoreboard li {
            padding: 2px;
            margin-bottom: 5px;
            font-size: 1rem;
        }

    .crown {
        color: gold;
        position: relative;
        top: -2px;
        margin-left: 5px;
    }

        .unfound {
            color: yellow;
        }

        .found {
            color: green;
        }

        .failed {
            color: red;
        }


    .sugest {
        color: white;
    }

    .timer, .response, .try {
        color: white;
    }

    .top-bar {
        position: fixed; /* ou 'absolute' si vous préférez qu'il soit relatif au parent positionné */
        top: 0;
        left: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }

    .volume-control {
        display: flex;
        align-items: center;
    }

        .volume-control label {
            margin-right: 10px;
            margin-top: 6px;
        }

    .volume-slider {
        width: 100px; /* Ajustez la largeur du slider ici */
    }


    @media (max-width: 600px) {
        .messages {
            width: 90%; /* Prendre toute la largeur de l'écran sur les petits appareils */
            max-height: 30vh; /* Définir une hauteur maximale */
            overflow-y: auto;
            bottom: 0; /* Positionner en bas de l'écran */
            top: auto; /* Désactiver le positionnement en haut */
            border-left: none; /* Enlever la bordure à gauche */
            box-shadow: 0px -2px 5px rgba(0,0,0,0.1); /* Ombre au-dessus pour séparer du contenu */
        }

        /* Cacher la barre de volume sur petits écrans */
        .volume-control {
            display: none;
        }

        /* Repositionner les scores */
        .scoreboard {
            margin-bottom: 20px; /* Ajouter de l'espace en dessous */
        }

        /* Ajuster le style du reste du contenu pour laisser de l'espace pour les scores */
        .game-room {
            padding-top: 70px; /* Donner de l'espace en haut pour les scores */
        }
    }

</style>
